@import '../Shared/layout';

form.notFound {
    margin-top:60px;
    input {
        max-width: 247px;
        height: 37px;
        font-size: 18px;
        padding: 0 7px;
        text-align: center;
        border: 1px solid #ddd;
    }

    button {
        font-size: 12px;
        background-color: #222222;
        height: 37px;
        padding: 0 20px;
        line-height: 37px;
        border: 0;
        border-radius: 0;
        display: block;
        width: auto;
        cursor: pointer;
        color: white;
        text-transform: uppercase;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        outline: none;
        display: inline-block;
        vertical-align: top;
        transition: 0.3s;
    }
}
